/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Label, Select } from "flowbite-react";
import { useMemo } from "react";
import { HiXCircle } from "react-icons/hi";

const FeaturedInstructionVideos = ({
  loading,
  videosAPI,
  instruction_videos_featured,
  setFeaturedInstructionVideos,
  instructionalVideos,
}: any) => {
  const filteredInstructionalVideos = useMemo(() => {
    const featuredVideoIds = new Set(
      instruction_videos_featured.map((video: any) => video.id),
    );
    return instructionalVideos.filter(
      (video: any) => !featuredVideoIds.has(video.id),
    );
  }, [instruction_videos_featured, instructionalVideos]);

  return (
    <>
      <div className="max-w-md" id="select">
        <div className="mb-2 block">
          <Label className="font-semibold" value="Choose Tips to feature." />
          <Label value="The videos have to be published in order to be selected." />
        </div>
        {loading ? (
          <>
            <p>Loading...</p>
          </>
        ) : (
          <>
            <Select
              id="featured-videos-select"
              onChange={async (e) => {
                const val = e.target.value;
                if (val !== "Select") {
                  const selected: any = instructionalVideos
                    ?.filter((x: any) => x.id == val)
                    .shift();

                  if (selected) {
                    const selectElement = document.getElementById(
                      "featured-videos-select",
                    ) as any;
                    if (selectElement != null)
                      selectElement.options[0].selected = true;

                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment

                    setFeaturedInstructionVideos([
                      ...instruction_videos_featured,
                      selected,
                    ]);

                    await videosAPI.setFeaturedInstructionalVideo(val, true);
                  }
                }
              }}
            >
              <option selected disabled value={"Select"}>
                Select
              </option>

              {filteredInstructionalVideos?.map((x: any) => {
                return (
                  <option key={x?.id} value={x?.id}>
                    {x?.title}
                  </option>
                );
              })}
            </Select>
          </>
        )}
      </div>

      <div className="flex flex-wrap -ml-1">
        {instruction_videos_featured.length > 0 &&
          instruction_videos_featured?.map((x: any, i: any) => {
            return (
              <>
                <span className="m-1 flex cursor-pointer flex-wrap items-center justify-between rounded bg-gray-200 px-4 py-2 text-xs font-bold leading-loose hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 sm:text-sm">
                  {x.title} &nbsp;
                  <span
                    onClick={async () => {
                      const data = instruction_videos_featured.filter(
                        (p: any) => p.id !== x.id,
                      );
                      setFeaturedInstructionVideos(data);

                      await videosAPI.setFeaturedInstructionalVideo(
                        x.id,
                        false,
                      );
                    }}
                    className="inline-flex cursor-pointer  text-red-700"
                  >
                    <HiXCircle className="relative top-0 size-4" />
                  </span>
                </span>

                {i === 4 && <br />}
              </>
            );
          })}
      </div>
    </>
  );
};

export default FeaturedInstructionVideos;
