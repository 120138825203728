import { Button, Label, Spinner } from "flowbite-react";
import { FC, useState, useEffect } from "react";
import { HiXCircle } from "react-icons/hi";
import {
  getAllImages,
  uploadNewImage,
  deleteImage,
} from "../../services/onboarding";
import { toast } from "react-toastify";

const OnboardingImages: FC = () => {
  const [images, setImages] = useState<
    { attachment_id: number; image_url: string; key: string }[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deletingIds, setDeletingIds] = useState<number[]>([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await getAllImages();
        setImages(response.data.results);
      } catch {
        toast.error("Error getting images");
      }
    };
    fetchImages();
  }, []);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];

      if (!file) {
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        if (!reader.result) {
          toast.error("Error loading image");
          return;
        }

        let base64String = reader.result as string;
        const formData = {
          cover_image: base64String,
          image_name: file.name,
          image_type: file.type,
        };

        setIsLoading(true);
        try {
          const response = await uploadNewImage(formData);
          console.log("Upload response:", response.data);

          const updatedResponse = await getAllImages();
          setImages(updatedResponse.data.results);
        } catch {
          toast.error("Error loading image");
        } finally {
          setIsLoading(false);
        }
      };
      reader.onerror = () => toast.error("Error loading image");
    }
  };

  const handleDeleteImage = async (key: string, attachment_id: number) => {
    setDeletingIds((prev) => [...prev, attachment_id]);
    setImages((prev) =>
      prev.filter((image) => image.attachment_id !== attachment_id),
    );
    try {
      await deleteImage(key, attachment_id);
    } catch (error) {
      toast.error("Error deleting image");
    } finally {
      setDeletingIds((prev) => prev.filter((id) => id !== attachment_id));
    }
  };

  return (
    <div className="max-w-md">
      <div className="mb-2 block">
        <Label
          className="font-semibold"
          value="Upload images to the onboarding carousel."
        />
        <Label value="" />
      </div>
      <input
        type="file"
        accept="image/*"
        multiple
        onChange={handleFileChange}
        className="hidden"
        id="filePicker"
      />
      <label htmlFor="filePicker">
        <Button className="w-[150px]" color="primary" as="span">
          Upload Image
        </Button>
      </label>

      <div className="grid grid-cols-3 gap-4 mt-4">
        {images.map((image) => (
          <div key={image.attachment_id} className="relative">
            {deletingIds.includes(image.attachment_id) ? (
              <div className="w-[200px] h-[300px] flex items-center justify-center bg-gray-100 rounded-lg">
                <Spinner color="info" size="xl" />
              </div>
            ) : (
              <>
                <img
                  src={image.image_url}
                  alt="Onboarding"
                  className="w-[200px] h-[300px] object-cover rounded-lg"
                />
                <button
                  className="absolute top-0 right-0 text-red-700 rounded-full p-1"
                  onClick={() =>
                    handleDeleteImage(image.key, image.attachment_id)
                  }
                >
                  <HiXCircle size={20} className="relative top-0 size-4" />
                </button>
              </>
            )}
          </div>
        ))}
        {isLoading && (
          <div className="w-[200px] h-[300px] text-center flex items-center justify-center">
            <Spinner color="info" size="xl" />
          </div>
        )}
      </div>
    </div>
  );
};

export default OnboardingImages;
