/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable tailwindcss/no-custom-classname */
import { useEffect, useMemo, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { createNewUser, getStripePrices } from "../../services/user";
import { toast } from "react-toastify";
import {
  Button,
  Label,
  Modal,
  TextInput,
  Spinner,
  Radio,
} from "flowbite-react";
import { HiPlus } from "react-icons/hi";

const AddUserModal = function (props: any) {
  const [isOpen, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [stripePrices, setStripePrices]: any = useState(null);

  const rootRef = useRef<HTMLDivElement>(null);

  const [user, setUser] = useState({
    email: "",
    password: "",
    plan: "",
    name: "",
    private_profile: false,
  });

  const formValid = useMemo(() => {
    return user.email && user.password && user.plan && user.name;
  }, [user.email, user.password, user.plan, user.name]);

  const handleChange = (e: any) => {
    const data: any = {
      ...user,
    };

    data[e.target.name] =
      e.target.name === "private_profile" ? e.target.checked : e.target.value;

    setUser(data);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      const new_user = await createNewUser(user);

      if (new_user.data.error) {
        toast.error(new_user.data.message);
        Sentry.captureMessage("Error creating a new user", {
          extra: {
            payload: user,
          },
        });
      } else {
        props.getUsers();
        setOpen(false);
        toast.success("New user created successfully!");
      }
    } catch (e: any) {
      const reqMessage = e?.response?.data?.message;
      const message = Array.isArray(reqMessage)
        ? reqMessage.join(", ")
        : reqMessage;
      toast.error(`Unable to create new user: ${message || e.message}`);
      Sentry.captureMessage("Error creating a new user", {
        extra: {
          payload: user,
        },
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      getStripePrices()
        .then((response) => {
          if (!response.data.error) {
            // Directly setting the monthly and yearly prices
            setStripePrices([
              ...response.data.results,
              {
                id: "free",
                label: "Life Time Access (free)",
              },
            ]);
            setUser((usr) => ({ ...usr, plan: "free" }));
          } else {
            toast.error("Failed to fetch pricing information.");
            Sentry.captureMessage("Failed to fetch pricing information");
          }
          setLoading(false);
        })
        .catch((error) => {
          toast.error("Failed to fetch prices");
          console.error("Failed to fetch prices:", error);
          Sentry.captureMessage("Failed to fetch pricing information");
          setLoading(false);
        });
    }
  }, [isOpen]);

  return (
    <>
      <div ref={rootRef}>
        <Button color="primary" onClick={() => setOpen(true)}>
          <div className="flex items-center gap-x-3">
            <HiPlus className="text-xl" />
            Add user
          </div>
        </Button>
        <Modal
          onClose={() => setOpen(false)}
          show={isOpen}
          root={rootRef.current ?? undefined}
          size="3xl"
          position="top-center"
        >
          <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
            <strong>Add New User</strong>
          </Modal.Header>
          <Modal.Body>
            {isLoading ? (
              <div className="flex items-center justify-center">
                <Spinner aria-label="Loading..." size="xl" />
              </div>
            ) : (
              <div>
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                  <div>
                    <Label htmlFor="firstName">Full name</Label>
                    <div className="mt-1">
                      <TextInput
                        key={1}
                        id="name"
                        name="name"
                        placeholder="Full Name"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div>
                    <Label htmlFor="lastName">Email</Label>
                    <div className="mt-1">
                      <TextInput
                        id="email"
                        name="email"
                        placeholder="example@company.com"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div>
                    <Label htmlFor="email">Password</Label>
                    <div className="mt-1">
                      <TextInput
                        id="password"
                        name="password"
                        placeholder="password"
                        type="password"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="flex items-start"></div>

                  {stripePrices?.map((price: any) => (
                    <div key={price.id} className="flex items-center gap-2">
                      <Radio
                        id={price.id}
                        name="plan"
                        value={price.id}
                        onChange={handleChange}
                        defaultChecked={price.id === user.plan}
                      />
                      <Label htmlFor={price.id}>{price.label}</Label>
                    </div>
                  ))}
                  <div className="flex items-start"></div>
                </div>
                <div
                  className="my-5 w-full items-center gap-6 rounded-lg bg-blue-50 text-sm  text-blue-800 dark:bg-gray-800 dark:text-blue-400 sm:grid-cols-2"
                  role="alert"
                >
                  <span className="sr-only">Info</span>
                  <div>
                    <svg
                      className="mr-3 inline size-4 shrink-0"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                    </svg>{" "}
                    By default each subscription will have 7 day trial Period
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitting || !formValid}
            >
              {isSubmitting ? "Creating..." : "Create"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default AddUserModal;
