import { Button, Table } from "flowbite-react";
import { useState } from "react";
import { HiOutlinePencilAlt, HiTrash } from "react-icons/hi";
import { toast } from "react-toastify";
import type { Exercise, ExerciseForm } from "types/apiResponses";
import { updateExercise } from "../../services/exercises";
import DeleteExerciseModal from "./DeleteExerciseModal";
import ExerciseModal from "./ExerciseModal";
import { useQueryClient } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";

export interface AllExercisesTableProps {
  exercises: Exercise[];
  getExercises: () => void;
  loading: boolean;
}

const AllExercisesTable = function (props: AllExercisesTableProps) {
  const [updating, setUpdating] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [exerciseFormData, setExerciseFormData] = useState<ExerciseForm>({
    id: "",
    name: "",
    exerciseType: "",
    equipment: "",
    muscles: [],
  });
  const queryClient = useQueryClient();

  const handleSubmitUpdate = async (partialExercise?: ExerciseForm) => {
    setUpdating(true);

    try {
      // Get the ID from the partial exercise or from the form data
      const id = partialExercise?.id || exerciseFormData.id;

      // Make a copy of partial exercise data without the ID property
      const exerciseDataToUpdate = { ...partialExercise } as any;

      // Remove the id from the data object to prevent the Bad Request error
      if (exerciseDataToUpdate.id) {
        delete exerciseDataToUpdate.id;
      }

      // Only send ID separately, use the partial data without the id field
      const updateResponse = await updateExercise(exerciseDataToUpdate, id);

      if (updateResponse.data.error) {
        toast.error(updateResponse.data.message);
        Sentry.captureMessage(updateResponse.data.message);
      } else {
        queryClient.invalidateQueries({
          queryKey: ["exerciseDetails", id],
        });
        props.getExercises();
        toast.success("Exercise updated successfully!");
      }
    } catch (error) {
      toast.error("Failed to update exercise");
      Sentry.captureException(error);
    } finally {
      setUpdating(false);
    }
  };

  const handleEditExercise = (exercise: Exercise) => {
    setExerciseFormData({
      id: exercise.id,
      name: exercise.name,
      exerciseType: exercise.exerciseType.id,
      equipment: exercise.equipment?.id || "",
      muscles: exercise.muscles.map((x: any) => x.id),
    });
    setOpen(true);
  };

  const handleDeleteExercise = (exercise: Exercise) => {
    setExerciseFormData({
      id: exercise.id,
      name: exercise.name,
      exerciseType: exercise.exerciseType.id,
      equipment: exercise.equipment?.id || "",
      muscles: exercise.muscles.map((x: any) => x.id),
    });
    setDeleteModalOpen(true);
  };

  return (
    <>
      <Table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
        <Table.Head className="bg-gray-100 dark:bg-gray-700">
          <Table.HeadCell>Name</Table.HeadCell>
          <Table.HeadCell>Type</Table.HeadCell>
          <Table.HeadCell>Muscles</Table.HeadCell>
          <Table.HeadCell>Actions</Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800">
          {props.exercises?.map((exercise: Exercise, index: number) => {
            return (
              <Table.Row
                key={index}
                className="hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                <Table.Cell className="mr-12 flex items-center space-x-6 whitespace-nowrap lg:mr-0">
                  <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                    <div className="text-base font-semibold text-gray-900 dark:text-white">
                      {exercise.name}
                    </div>
                  </div>
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap text-base font-medium text-gray-900 dark:text-white">
                  {exercise.exerciseType.name}
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap text-base font-medium text-gray-900 dark:text-white">
                  {exercise.muscles?.map((muscle: any) => {
                    return (
                      <span key={muscle.name} className="p-2 text-xs">
                        {muscle.name}
                      </span>
                    );
                  })}
                </Table.Cell>
                <Table.Cell className="flex gap-2">
                  <Button
                    color="primary"
                    onClick={() => handleEditExercise(exercise)}
                  >
                    <div className="flex items-center gap-x-2">
                      <HiOutlinePencilAlt className="text-lg" />
                      Edit Exercise
                    </div>
                  </Button>
                  <Button
                    color="failure"
                    onClick={() => handleDeleteExercise(exercise)}
                  >
                    <div className="flex items-center gap-x-2">
                      <HiTrash className="text-lg" />
                      Delete
                    </div>
                  </Button>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      {isOpen && (
        <ExerciseModal
          id={exerciseFormData.id}
          updating={updating}
          isOpen={isOpen}
          exercise={exerciseFormData}
          setExercise={setExerciseFormData}
          handleSubmit={handleSubmitUpdate}
          handleClose={() => setOpen(false)}
        />
      )}
      {deleteModalOpen && (
        <DeleteExerciseModal
          exercise={exerciseFormData}
          getExercises={props.getExercises}
          isOpen={deleteModalOpen}
          setIsOpen={setDeleteModalOpen}
        />
      )}
    </>
  );
};

export default AllExercisesTable;
