import axios from "../util/axios";
import config from "../config/config";

export async function getAllImages() {
  return axios.get(`${config.defaults.api_url}/onboarding/image/all`);
}

export async function uploadNewImage(params: any) {
  return axios.post(
    `${config.defaults.api_url}/onboarding/image/create`,
    params,
  );
}

export async function deleteImage(key: string, attachment_id: number) {
  return axios.delete(`${config.defaults.api_url}/onboarding/image/delete`, {
    data: { key, attachment_id },
  });
}
