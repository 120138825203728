import { Breadcrumb, Label, Select } from "flowbite-react";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { HiHome, HiXCircle } from "react-icons/hi";
import NavbarSidebarLayout from "../../layouts/navbar-sidebar";
import { useDispatch, useSelector } from "react-redux";
import ActionType from "../../redux/actionTypes";
import {
  featuredTrainer,
  getAllTrainers,
  getFeaturedTrainers,
} from "../../services/trainers";
import { getPrograms } from "../../redux/actionCreators/programs";
import AsyncSelect from "react-select/async";
import {
  changeDailyWorkout,
  featuredWorkout,
  getAllWorkouts,
  getDailyWorkouts,
  getFeaturedWorkouts,
} from "../../services/workouts";
import { featuredProgram, getFeaturedPrograms } from "../../services/programs";
import FeaturedInstructionVideos from "./FeaturedInstructionalVideos";
import * as videosAPI from "../../services/videos";
import { ToastContainer, toast } from "react-toastify";
import { isCancelReq } from "../../util/axios";
import WelcomeVideo from "./WelcomeVideo";
import FeaturedExercises from "./FeaturedExercises";
import OnboardingImages from "./OnboardingImages";

interface RootState {
  auth: any;
  users: any;
  workouts: any;
  programs: any;
}

const Spotlight: FC = function () {
  const dispatch: any = useDispatch();
  const state: any = useSelector((state: RootState) => state);

  const [programs, setPrograms]: any = useState([]);
  const [trainers, setTrainers]: any = useState([]);
  const [instructionalVideos, setInstructionalVideos]: any = useState([]);
  const [loading, setIsLoading] = useState(false);

  const [programs_featured, setFeaturedPrograms]: any = useState([]);
  const [trainers_featured, setFeaturedTrainers] = useState([]);
  const [workouts_featured, setFeaturedWokrouts]: any = useState([]);
  const [workout_daily, setWorkoutDaily]: any = useState([]);
  const [instruction_videos_featured, setFeaturedInstructionVideos] = useState(
    [],
  );

  const loadData = async (query: string) => {
    const response = await getAllWorkouts({
      page: 1,
      take: 20,
      search: query,
      dropdown: true,
    });

    const data = response.data.results.workouts;

    return data;
  };

  useEffect(() => {
    const init = async () => {
      try {
        const _trainers = await getAllTrainers({});

        setTrainers(_trainers?.data?.results.trainers);

        const _fp = await getFeaturedPrograms();

        setFeaturedPrograms(_fp.data.results);

        const _ft = await getFeaturedTrainers();

        setFeaturedTrainers(_ft.data.results);

        const _fw = await getFeaturedWorkouts();

        setFeaturedWokrouts(_fw.data.results);

        const dailyWorkouts = await getDailyWorkouts();

        setWorkoutDaily(dailyWorkouts);

        // Get featured instructional videos
        const _fv = await videosAPI.getFeaturedInstructionalVideos();

        setFeaturedInstructionVideos(_fv.data.results.videos);

        const instructionalVideosReq =
          await videosAPI.getAllInstructionalVideos();

        setInstructionalVideos(instructionalVideosReq.data.results.videos);
      } catch (error) {
        if (!isCancelReq(error)) {
          console.log(error);
        }
      }
    };

    init();
  }, []);

  const getProgramData = () => {
    setIsLoading(true);
    dispatch(
      getPrograms({
        page: 1,
        take: 50,
      }),
    );
  };

  useEffect(() => {
    getProgramData();
  }, []);

  useEffect(() => {
    const init = async () => {
      try {
        const trainers: any = await getAllTrainers({});
        setTrainers(trainers.data.results.trainers);
      } catch (error) {
        if (!isCancelReq(error)) {
          console.log(error);
        }
      }
    };

    init();
  }, []);

  useEffect(() => {
    if (state.programs.event === ActionType.GET_ALL_PROGRAMS) {
      setIsLoading(false);
      setPrograms(state.programs.programs);
    } else if (state?.programs?.type == ActionType.GET_ALL_PROGRAMS_FAILED) {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <>
      <NavbarSidebarLayout isFooter={false}>
        <div className="block items-start justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
          <div className="mb-1 w-full">
            <div className="mb-4">
              <Breadcrumb className="mb-4">
                <Breadcrumb.Item href="/">
                  <div className="flex items-center gap-x-3">
                    <HiHome className="text-xl" />
                    <span className="dark:text-white">Home</span>
                  </div>
                </Breadcrumb.Item>
                <Breadcrumb.Item href="/spotlight">Spotlight</Breadcrumb.Item>
              </Breadcrumb>
              <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                Spotlight
              </h1>
            </div>
            <div className="mt-3 w-full flex flex-col gap-6 mb-10">
              <WelcomeVideo />
              <FeaturedExercises />
              <div className="flex flex-col">
                <div className="max-w-md mb-6" id="select">
                  <div className="mb-2 block">
                    <Label
                      className="font-semibold"
                      value="Choose athletes to feature"
                    />
                  </div>
                  <Select
                    onChange={async (e) => {
                      const selected: any = trainers
                        .filter((x: any) => x.id == e.target.value)
                        .shift();

                      if (selected) {
                        if (
                          trainers_featured.some(
                            (t: any) => t.id === selected.id,
                          )
                        ) {
                          toast.error("Trainer already selected");
                          return;
                        }
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore:next-line
                        setFeaturedTrainers([...trainers_featured, selected]);
                        await featuredTrainer(
                          {
                            featured: true,
                          },
                          e.target.value,
                        );
                      }
                    }}
                  >
                    <option selected disabled>
                      Select
                    </option>

                    {trainers?.map((x: any, i: number) => {
                      return (
                        <option key={i} value={x?.id}>
                          {x?.title}
                        </option>
                      );
                    })}
                  </Select>
                </div>

                <div className="flex flex-wrap -ml-1">
                  {trainers_featured?.map((x: any, i: any) => {
                    return (
                      <div key={i}>
                        <span className="m-1 flex cursor-pointer flex-wrap items-center justify-between rounded bg-gray-200 px-4 py-2 text-xs font-bold leading-loose hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 sm:text-sm">
                          {x.title} &nbsp;
                          <span
                            onClick={async () => {
                              const data = trainers_featured.filter(
                                (t: any) => t.id !== x.id,
                              );
                              setFeaturedTrainers(data);

                              await featuredTrainer(
                                {
                                  featured: false,
                                },
                                x.id,
                              );
                            }}
                            className="inline-flex cursor-pointer  text-red-700"
                          >
                            <HiXCircle className="relative top-0 size-4" />
                          </span>
                        </span>

                        {i === 4 && <br />}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="max-w-md mb-6" id="select">
                  <div className="mb-2 block">
                    <Label
                      className="font-semibold"
                      value="Choose Programs to feature"
                    />
                  </div>
                  {loading ? (
                    <>
                      <p>Loading...</p>
                    </>
                  ) : (
                    <>
                      <Select
                        onChange={async (e) => {
                          const selected: any = programs?.data
                            ?.filter((x: any) => x.id == e.target.value)
                            .shift();

                          if (selected) {
                            if (
                              programs_featured.some(
                                (p: any) => p.id === selected.id,
                              )
                            ) {
                              toast.error("Program already selected");
                              return;
                            }
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore:next-line
                            setFeaturedPrograms([
                              ...programs_featured,
                              selected,
                            ]);
                            await featuredProgram(
                              {
                                featured: true,
                              },
                              e.target.value,
                            );
                          }
                        }}
                      >
                        <option selected disabled>
                          Select
                        </option>

                        {programs.data?.map((x: any) => {
                          return (
                            <>
                              <option value={x?.id}>{x?.title}</option>
                            </>
                          );
                        })}
                      </Select>
                    </>
                  )}
                </div>

                <div className="flex flex-wrap -ml-1">
                  {programs_featured?.map((x: any, i: any) => {
                    return (
                      <>
                        <span className="m-1 flex cursor-pointer flex-wrap items-center justify-between rounded bg-gray-200 px-4 py-2 text-xs font-bold leading-loose hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 sm:text-sm">
                          {x.title} &nbsp;
                          <span
                            onClick={async () => {
                              const data = programs_featured.filter(
                                (p: any) => p.id !== x.id,
                              );
                              setFeaturedPrograms(data);

                              await featuredProgram(
                                {
                                  featured: false,
                                },
                                x.id,
                              );
                            }}
                            className="inline-flex cursor-pointer  text-red-700"
                          >
                            <HiXCircle className="relative top-0 size-4" />
                          </span>
                        </span>

                        {i === 4 && <br />}
                      </>
                    );
                  })}
                </div>
              </div>
              <div className=" max-w-md">
                <div className="mb-2 block">
                  <Label className="font-semibold">
                    {" "}
                    Choose Daily Workout{" "}
                  </Label>
                </div>
                <AsyncSelect
                  isClearable={true}
                  className=""
                  defaultValue={[]}
                  loadOptions={loadData}
                  noOptionsMessage={() => "No results"}
                  onChange={async (v: any) => {
                    if (v) {
                      if (workout_daily.some((w: any) => w.id === v.value)) {
                        toast.error("Daily Workout already selected");
                        return;
                      }
                      setWorkoutDaily([
                        {
                          id: v.value,
                          title: v.label,
                        },
                      ]);
                      await changeDailyWorkout(v.value);
                      toast.success("Daily Workout updated successfully!");
                    }
                  }}
                />
              </div>
              <div className="-ml-1 flex flex-wrap">
                {workout_daily?.map((x: any, i: any) => {
                  return (
                    <>
                      <span className="m-1 flex cursor-pointer flex-wrap items-center justify-between rounded bg-gray-200 px-4 py-2 text-xs font-bold leading-loose hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 sm:text-sm">
                        {x.title} &nbsp;
                      </span>

                      {i === 4 && <br />}
                    </>
                  );
                })}
              </div>
              <div className="flex flex-col">
                <div className="max-w-md mb-6">
                  <div className="mb-2 block">
                    <Label className="font-semibold">
                      Choose Workouts to feature
                    </Label>
                  </div>
                  <AsyncSelect
                    isClearable={true}
                    className=""
                    defaultValue={[]}
                    loadOptions={loadData}
                    onInputChange={loadData}
                    noOptionsMessage={() => "No results"}
                    onChange={async (v: any) => {
                      const selected: any = workouts_featured
                        .filter((x: any) => x.id == v.value)
                        .shift();

                      if (selected) {
                        toast.error("Workout already selected");
                        return;
                      }
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore:next-line
                      setFeaturedWokrouts([
                        ...workouts_featured,
                        {
                          id: v.value,
                          title: v.label,
                        },
                      ]);
                      await featuredWorkout(
                        {
                          featured: true,
                        },
                        v.value,
                      );
                    }}
                  />
                </div>

                <div className="-ml-1 flex flex-wrap">
                  {workouts_featured?.map((x: any, i: any) => {
                    return (
                      <>
                        <span className="m-1 flex cursor-pointer flex-wrap items-center justify-between rounded bg-gray-200 px-4 py-2 text-xs font-bold leading-loose hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 sm:text-sm">
                          {x.title} &nbsp;
                          <span
                            onClick={async () => {
                              const data = workouts_featured.filter(
                                (w: any) => w.id !== x.id,
                              );
                              setFeaturedWokrouts(data);
                              await featuredWorkout(
                                {
                                  featured: false,
                                },
                                x.id,
                              );
                            }}
                            className="inline-flex cursor-pointer  text-red-700"
                          >
                            <HiXCircle className="relative top-0 size-4" />
                          </span>
                        </span>

                        {i === 4 && <br />}
                      </>
                    );
                  })}
                </div>
              </div>
              <FeaturedInstructionVideos
                loading={loading}
                videosAPI={videosAPI}
                instruction_videos_featured={instruction_videos_featured}
                setFeaturedInstructionVideos={setFeaturedInstructionVideos}
                instructionalVideos={instructionalVideos}
              />
              <OnboardingImages />
            </div>
          </div>
        </div>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
          theme="dark"
        />
      </NavbarSidebarLayout>
    </>
  );
};

export default Spotlight;
