import { Button, Label, TextInput } from "flowbite-react";
import { FC, useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import axios from "../../util/axios";
import config from "../../config/config";
import type { VideosResponse, Video } from "../../interfaces/VideoResponse";
import { HiXCircle } from "react-icons/hi";
import * as Sentry from "@sentry/react";

const FeaturedExercises: FC = () => {
  const [featuredExercises, setFeaturedExercises] = useState<Video[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAliasVideo, setSelectedAliasVideo] = useState<Video | null>(
    null,
  );
  const [aliasTitle, setAliasTitle] = useState("");
  const [isAliasSaving, setIsAliasSaving] = useState(false);

  // Fetch current featured exercises on mount
  useEffect(() => {
    const fetchFeaturedExercises = async () => {
      try {
        const response = await axios.get(`${config.defaults.api_url}/videos`, {
          params: {
            page: 1,
            take: 10,
            is_featured_exercise: true,
            format: "short",
          },
        });
        const data = response.data as VideosResponse;
        if (data.results.videos?.length) {
          console.log(data.results.videos);
          setFeaturedExercises(
            data.results.videos.map((video) => ({
              id: video.id,
              title: video.title,
              alias_title: video.alias_title || null,
              video_duration: video.video_duration,
            })),
          );
        }
      } catch (error) {
        console.error("Error fetching featured exercises:", error);
      }
    };

    fetchFeaturedExercises();
  }, []);

  // Load options for selection (fetch non-featured exercises)
  const loadVideoOptions = async (query: string) => {
    try {
      const response = await axios.get(`${config.defaults.api_url}/videos`, {
        params: {
          search: query || undefined,
          page: 1,
          take: 20,
          is_featured_exercise: false,
          format: "short",
        },
      });
      const data = response.data as VideosResponse;
      return data.results.videos.map((video) => ({
        value: video.id,
        label: video.title,
      }));
    } catch (error) {
      console.error("Error loading videos:", error);
      return [];
    }
  };

  // Update featured exercise by POSTing to update endpoint
  const handleVideoChange = async (
    videoId: string,
    featured: boolean,
  ): Promise<boolean> => {
    setIsLoading(true);
    try {
      await axios.post(`${config.defaults.api_url}/videos/update/${videoId}`, {
        is_featured_exercise: featured,
      });
      if (featured) {
        toast.success("Featured Exercise added successfully!");
      } else {
        toast.success("Featured Exercise removed successfully!");
      }
      return true;
    } catch (error) {
      toast.error(`Failed to ${featured ? "add" : "remove"} featured exercise`);
      console.error("Error updating featured exercise:", error);
      Sentry.captureMessage("Error updating featured exercise", {
        extra: {
          id: videoId,
        },
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  // New function to handle alias saving
  const handleAliasSave = async () => {
    if (!selectedAliasVideo) {
      toast.error("No exercise selected for alias update");
      return;
    }
    setIsAliasSaving(true);
    try {
      await axios.post(
        `${config.defaults.api_url}/videos/update/${selectedAliasVideo.id}`,
        { alias_title: aliasTitle },
      );
      // Update state with new alias
      setFeaturedExercises((prev) =>
        prev.map((video) =>
          video.id === selectedAliasVideo.id
            ? { ...video, alias_title: aliasTitle }
            : video,
        ),
      );
      toast.success("Alias updated successfully!");
    } catch (error) {
      toast.error("Failed to update alias");
      console.error("Error updating alias:", error);
      Sentry.captureMessage("Failed to update alias", {
        extra: {
          id: selectedAliasVideo.id,
        },
      });
    } finally {
      setIsAliasSaving(false);
    }
  };

  return (
    <div className="flex flex-col">
      {/* Input for adding featured exercises */}
      <div className="max-w-md mb-6">
        <div className="mb-2 flex flex-row items-center gap-4">
          <Label className="font-semibold">Choose Featured Exercise</Label>
          {(isLoading || isAliasSaving) && (
            <div className="animate-spin h-4 w-4 border-2 border-gray-500 rounded-full border-t-transparent" />
          )}
        </div>
        <AsyncSelect
          isClearable={true}
          defaultOptions
          cacheOptions
          loadOptions={loadVideoOptions}
          noOptionsMessage={() => "No results"}
          onChange={async (v: any) => {
            if (v) {
              // Prevent duplicate selection
              if (featuredExercises.some((video) => video.id === v.value)) {
                toast.error("Exercise already featured");
                return;
              }
              // Update state and backend
              setFeaturedExercises([
                ...featuredExercises,
                {
                  id: v.value,
                  title: v.label,
                  alias_title: null,
                  video_duration: 0,
                },
              ]);
              await handleVideoChange(v.value, true);
            }
          }}
        />
      </div>
      {/* Render list of featured exercises */}
      {featuredExercises.length > 0 && (
        <div className="flex flex-wrap gap-2">
          {featuredExercises.map((video) => (
            <span
              key={video.id}
              onClick={() => {
                setSelectedAliasVideo(video);
                setAliasTitle(
                  video.alias_title ? video.alias_title : video.title,
                );
              }}
              className="m-1 flex cursor-pointer flex-wrap items-center justify-between rounded bg-gray-200 px-4 py-2 text-xs font-bold leading-loose hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 sm:text-sm"
            >
              {video.title} &nbsp;
              <span
                onClick={async (e) => {
                  e.stopPropagation();
                  // Optimistically update state by removing the exercise immediately
                  const previousExercises = [...featuredExercises];
                  setFeaturedExercises(
                    featuredExercises.filter((vid) => vid.id !== video.id),
                  );
                  const success = await handleVideoChange(
                    video.id.toString(),
                    false,
                  );
                  if (!success) {
                    // Revert state update if deletion failed
                    setFeaturedExercises(previousExercises);
                  }
                }}
                className="inline-flex cursor-pointer text-red-700"
              >
                <HiXCircle className="relative top-0 size-4" />
              </span>
            </span>
          ))}
        </div>
      )}

      {featuredExercises.length > 0 && (
        <div className="flex flex-row -ml-0 max-w-lg  gap-4 mt-6">
          <TextInput
            id="small"
            type="text"
            placeholder="Alias title"
            value={aliasTitle}
            onChange={(e) => setAliasTitle(e.target.value)}
          />
          <Button
            color="primary"
            onClick={handleAliasSave}
            disabled={isLoading || isAliasSaving}
          >
            Save Alias
          </Button>
        </div>
      )}
    </div>
  );
};

export default FeaturedExercises;
