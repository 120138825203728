import axios from "axios";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { HiOutlineCloudUpload, HiVideoCamera } from "react-icons/hi";
import { toast } from "react-toastify";
import config from "../../config/config";
import { FaFilePdf } from "react-icons/fa6";
import { FaExternalLinkAlt } from "react-icons/fa";
interface FileUploadZoneProps {
  acceptedTypes: string[];
  maxSize?: number;
  defaultPreview?: FilePreview;
  onFileChange?: (file: File) => void;
  onImageChange?: (image: ImageFile) => void;
  onVideoChange?: (video: VideoFile) => void;
  onDocumentChange?: (document: DocumentFile) => void;
  maxDimensions?: { width: number; height: number };
}

interface FilePreview {
  name?: string;
  url?: string;
  type?: string;
}
export interface DocumentFile {
  name: string;
  type: string;
  dataUri: string;
}
export interface ImageFile {
  name: string;
  type: string;
  dataUri: string;
}
export interface VideoFile {
  name?: string;
  type?: string;
  duration?: number;
  size?: string;
  storageKey?: string;
  progress?: number;
  status?: "uploading" | "uploaded" | "failed";
}

export const getVideoDuration = async (file: File): Promise<number> => {
  return new Promise((resolve, reject) => {
    const videoUrl = URL.createObjectURL(file);
    const videoElement = document.createElement("video");
    videoElement.src = videoUrl;

    videoElement.addEventListener("loadedmetadata", () => {
      const duration = Math.floor(videoElement.duration);
      // Delay revocation to avoid Safari timing issues
      setTimeout(() => URL.revokeObjectURL(videoUrl), 1000);
      resolve(duration);
    });

    videoElement.addEventListener("error", () => {
      URL.revokeObjectURL(videoUrl);
      reject(new Error("Failed to load video metadata."));
    });
  });
};

const getImageData = async (file: File): Promise<ImageFile> => {
  const dataUri = await readFileAsDataUri(file);

  return {
    name: file.name,
    type: file.type,
    dataUri,
  };
};

const readFileAsDataUri = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

const validateImageDimensions = (
  file: File,
  maxDimensions: { width: number; height: number },
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);
    img.src = objectUrl;

    img.onload = () => {
      URL.revokeObjectURL(objectUrl);
      if (
        img.width > maxDimensions.width ||
        img.height > maxDimensions.height
      ) {
        reject(
          new Error(
            `Image dimensions exceed the maximum allowed size of ${maxDimensions.width}x${maxDimensions.height}px.`,
          ),
        );
      } else {
        resolve();
      }
    };

    img.onerror = () => {
      URL.revokeObjectURL(objectUrl);
      reject(new Error("Failed to load image."));
    };
  });
};

const validateFile = async (
  file: File,
  acceptedTypes: string[],
  maxSize: number,
  maxDimensions?: { width: number; height: number },
): Promise<void> => {
  if (acceptedTypes.length && !acceptedTypes.includes(file.type)) {
    throw new Error("Invalid file type.");
  }

  if (file.size > maxSize) {
    const sizeInMB = maxSize / (1024 * 1024);
    const limitFormatted =
      sizeInMB >= 1000
        ? Math.floor(maxSize / (1024 * 1024 * 1024)) + " GB"
        : sizeInMB.toFixed(0) + " MB";
    throw new Error(`File is too large. Max size is ${limitFormatted}.`);
  }

  if (file.type.startsWith("image/") && maxDimensions) {
    await validateImageDimensions(file, maxDimensions);
  }
};

const FileUploadZone: React.FC<FileUploadZoneProps> = ({
  acceptedTypes = [],
  maxSize = 10485760,
  defaultPreview,
  onFileChange,
  onImageChange,
  onVideoChange,
  onDocumentChange,
  maxDimensions,
}) => {
  // Compute formatted max size
  const formattedMaxSize =
    maxSize / (1024 * 1024) >= 1000
      ? Math.floor(maxSize / (1024 * 1024 * 1024)) + " GB"
      : (maxSize / (1024 * 1024)).toFixed(0) + " MB";

  const [thumbnail, setThumbnail] = useState<string | React.ReactNode | null>(
    // Don't automatically use defaultPreview.url for video files
    defaultPreview?.url && !defaultPreview?.type?.startsWith("video/")
      ? defaultPreview.url
      : null,
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length) {
        const fileUploaded = acceptedFiles[0];

        if (fileUploaded) {
          try {
            // Validate file
            await validateFile(
              fileUploaded,
              acceptedTypes,
              maxSize,
              maxDimensions,
            );

            // Handle video upload
            if (fileUploaded.type.startsWith("video/") && onVideoChange) {
              // Don't set the thumbnail for videos
              // Instead show a video icon which will be handled in the render logic
              setThumbnail(
                <HiVideoCamera
                  size={60}
                  className="text-gray-500 h-[100px] mb-2"
                />,
              );

              const videoDetails: VideoFile = {
                name: fileUploaded.name,
                type: fileUploaded.type,
                duration: await getVideoDuration(fileUploaded),
                size: (fileUploaded.size / (1024 * 1024)).toFixed(2),
                progress: 0,
                status: "uploading",
              };

              // Notify the start of the upload
              onVideoChange(videoDetails);

              // Perform the upload
              const formData = new FormData();
              formData.append("file", fileUploaded);

              const uploadResponse = await axios.post(
                `${config.defaults.api_url}/user/video`,
                formData,
                {
                  onUploadProgress: (progressEvent) => {
                    const progress = progressEvent.total
                      ? (progressEvent.loaded / progressEvent.total) * 100
                      : 0;
                    onVideoChange({
                      ...videoDetails,
                      progress,
                    });
                  },
                },
              );

              // Update the video details after successful upload
              onVideoChange({
                ...videoDetails,
                storageKey: uploadResponse.data?.Key,
                progress: 100,
                status: "uploaded",
              });

              toast.success("Video uploaded successfully!");
            } else if (
              fileUploaded.type.startsWith("image/") &&
              onImageChange
            ) {
              const imageData = await getImageData(fileUploaded);

              setThumbnail(imageData.dataUri);
              onImageChange(imageData);
            } else if (
              fileUploaded.type.startsWith("application/") &&
              onDocumentChange
            ) {
              const dataUri = await readFileAsDataUri(fileUploaded);

              const documentData: DocumentFile = {
                name: fileUploaded.name,
                type: fileUploaded.type,
                dataUri,
              };
              setThumbnail(
                <FaFilePdf
                  size={60}
                  className="text-gray-500 h-[100px] mb-2"
                />,
              );
              onDocumentChange(documentData);
              toast.success("Document uploaded successfully!");
            } else if (onFileChange) {
              setThumbnail(null);
              onFileChange(fileUploaded);
            }
          } catch (error) {
            if (onVideoChange) {
              onVideoChange({
                name: fileUploaded.name,
                type: fileUploaded.type,
                status: "failed",
              });
            }
            if (error instanceof Error) {
              toast.error(error.message);
            } else {
              toast.error("An unknown error occurred.");
            }
          }
        }
      }
    },
    [
      acceptedTypes,
      maxSize,
      maxDimensions,
      onFileChange,
      onImageChange,
      onVideoChange,
    ],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedTypes.length
      ? acceptedTypes.reduce((acc, type) => ({ ...acc, [type]: [] }), {})
      : undefined,
  });

  const truncateFileName = (name: string) => {
    const maxLength = 20;
    if (name.length > maxLength) {
      return name.substring(0, maxLength) + "...";
    }
    return name;
  };

  const getFileExtensions = (mimeTypes: string[]) => {
    return mimeTypes
      .map((mime) => {
        const parts = mime.split("/");
        return parts[1] ? parts[1].toUpperCase() : "";
      })
      .join(", ");
  };

  // Add this custom thumbnail component for image previews from URLs
  const ImagePreviewFromUrl = ({ url }: { url: string }) => (
    <img
      src={url}
      alt="Image preview"
      className="mb-2"
      style={{ maxHeight: "100px", objectFit: "contain" }}
      onError={() => {
        console.error("Failed to load image preview:", url);
        setThumbnail(previewIcon); // Fallback to default icon on error
      }}
    />
  );

  let previewIcon: React.ReactNode;

  if (defaultPreview?.type?.startsWith("video/")) {
    previewIcon = (
      <HiVideoCamera size={60} className="text-gray-500 mb-2 h-[100px]" />
    );
  } else if (defaultPreview?.type?.startsWith("application/")) {
    previewIcon = (
      <FaFilePdf size={60} className="text-gray-500 mb-2 h-[100px]" />
    );
  } else {
    previewIcon = (
      <HiOutlineCloudUpload
        size={60}
        className="text-gray-500 mb-2 h-[100px]"
      />
    );
  }

  let thumbnailComponent: React.ReactNode;

  if (
    typeof thumbnail === "string" &&
    !defaultPreview?.type?.startsWith("video/")
  ) {
    // Only use image tag for non-video files
    thumbnailComponent = (
      <img
        src={thumbnail}
        alt="File thumbnail"
        className="mb-2"
        style={{ maxHeight: "100px", objectFit: "contain" }}
      />
    );
  } else if (typeof thumbnail === "object") {
    thumbnailComponent = thumbnail;
  } else if (defaultPreview?.type?.startsWith("video/")) {
    // For video files, always show the video icon
    thumbnailComponent = (
      <div className="flex flex-col justify-center items-center h-[100px] mb-2">
        <HiVideoCamera size={60} className="text-gray-500 -mb-1" />
        <p className="text-xs text-gray-500 dark:text-gray-400">
          {truncateFileName(defaultPreview?.name || "Video file")}
        </p>
      </div>
    );
  }

  return (
    <div
      {...getRootProps()}
      className={`flex mt-1 h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed ${
        isDragActive ? "bg-blue-100" : "bg-gray-50 relative"
      } hover:bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600`}
    >
      <input {...getInputProps()} />
      {defaultPreview?.type?.startsWith("application/") &&
        defaultPreview?.url && (
          <div className="absolute top-2 right-2 text-gray-500 dark:text-gray-400 ">
            <a
              href={defaultPreview.url}
              target="_blank"
              rel="noreferrer"
              className="flex items-center font-semibold  p-1 gap-1"
              onClick={(e) => e.stopPropagation()}
            >
              <span>View Document</span>
              <FaExternalLinkAlt />
            </a>
          </div>
        )}
      <div className="flex flex-col items-center justify-center pb-6 pt-5">
        {/* Show the correct thumbnail or icon based on file type */}
        {thumbnail ? (
          thumbnailComponent
        ) : defaultPreview?.url &&
          defaultPreview?.type?.startsWith("image/") ? (
          <ImagePreviewFromUrl url={defaultPreview.url} />
        ) : defaultPreview?.type?.startsWith("video/") ? (
          <div className="flex flex-col justify-center items-center h-[100px] mb-2">
            <HiVideoCamera size={60} className="text-gray-500 -mb-1" />
            <p className="text-xs text-gray-500 dark:text-gray-400">
              {truncateFileName(
                defaultPreview?.name || defaultPreview?.url || "Video file",
              )}
            </p>
          </div>
        ) : (
          previewIcon
        )}
        <p className="mb-1 text-sm text-gray-500 dark:text-gray-400">
          <span className="font-semibold">
            {isDragActive
              ? "Drop file"
              : defaultPreview?.url || thumbnail
                ? "Click to replace"
                : "Click to upload"}
          </span>{" "}
          or drag and drop
        </p>
        <p className="text-xs text-gray-500 dark:text-gray-400">
          {acceptedTypes.length
            ? getFileExtensions(acceptedTypes.slice(0, 3))
            : ""}{" "}
          (Max: {formattedMaxSize}
          {maxDimensions
            ? `, ${maxDimensions.width}x${maxDimensions.height}px`
            : ""}
          )
        </p>
      </div>
    </div>
  );
};

export default FileUploadZone;
