import { toast } from "react-toastify";
import type { CustomFlowbiteTheme } from "flowbite-react";
import {
  Button,
  Label,
  Modal,
  Spinner,
  TextInput,
  Textarea,
} from "flowbite-react";
import { useEffect, useState } from "react";
import {
  getAllEquipment,
  getAllExerciseTypes,
  getAllMuscles,
  getExerciseDetails,
} from "../../services/exercises";
import type { Equipment, ExerciseForm, Muscle } from "../../types/apiResponses";
import { useQuery } from "@tanstack/react-query";
import FileUploadZone, { ImageFile } from "../videos/FileUploadZone";

export const blueThemeSpinner: CustomFlowbiteTheme["spinner"] = {
  color: {
    info: "fill-blue-700",
  },
};

export const ThemeTooltip: CustomFlowbiteTheme["tooltip"] = {
  target: "w-full",
};

export interface ExerciseModalProps {
  id?: string;
  updating: boolean;
  exercise: ExerciseForm;
  isOpen: boolean;
  setExercise: (exercise: ExerciseForm) => void;
  handleSubmit: (exerciseData?: ExerciseForm) => Promise<void>;
  handleClose: () => void;
}

const ExerciseModal = function (props: ExerciseModalProps) {
  const {
    id,
    isOpen,
    updating,
    exercise,
    setExercise,
    handleSubmit,
    handleClose,
  } = props;

  // Track which fields have been modified
  const [touchedFields, setTouchedFields] = useState<Record<string, boolean>>(
    {},
  );

  const typesData = useQuery({
    queryKey: ["typesData"],
    queryFn: () => fetchTypesData(),
  });

  const exerciseDetails = useQuery({
    queryKey: ["exerciseDetails", id],
    queryFn: () => fetchExerciseDetails(),
  });

  const equipment = useQuery({
    queryKey: ["equipment"],
    queryFn: async () => {
      const response = await getAllEquipment();
      return response;
    },
  });

  // Add this to check if any query is loading
  const isLoading =
    typesData.isLoading || exerciseDetails.isLoading || equipment.isLoading;

  const fetchExerciseDetails = async () => {
    if (id) {
      const exerciseData = await getExerciseDetails(id);
      return {
        id: exerciseData.results.id,
        name: exerciseData.results.name,
        description: exerciseData.results.description || "",
        repCount: exerciseData.results.repCount || "",
        setCount: exerciseData.results.setCount || "",
        exerciseType: exerciseData.results.exerciseType.id,
        equipment: exerciseData.results.equipment?.id || "",
        muscles: exerciseData.results.muscles.map((x: any) => x.id),
        imageUrl: exerciseData.results.imageUrl || "",
      };
    } else {
      return {
        id: "",
        name: "",
        description: "",
        repCount: "",
        setCount: "",
        exerciseType: "",
        equipment: "1f38ca0b-73eb-499f-8074-8daa7ec4c1b8", // No equipment
        muscles: [],
      };
    }
  };

  const fetchTypesData = async () => {
    const musclesData = (await getAllMuscles()).results;
    const typesData = (await getAllExerciseTypes()).results;
    return {
      typesData,
      musclesData,
    };
  };

  useEffect(() => {
    if (exerciseDetails.data) {
      setExercise(exerciseDetails.data);
      // Reset touched fields when loading new data
      setTouchedFields({});
    }
  }, [exerciseDetails.data]);

  const handleInput = (e: any) => {
    const { name } = e.target;
    setExercise({ ...exercise, [name]: e.target.value });
    setTouchedFields((prev) => ({ ...prev, [name]: true }));
  };

  const handleSelectMuscle = (e: any) => {
    if (exercise.muscles.includes(e.target.value)) {
      setExercise({
        ...exercise,
        muscles: exercise.muscles.filter((x) => x !== e.target.value),
      });
    } else {
      setExercise({
        ...exercise,
        muscles: [...exercise.muscles, e.target.value],
      });
    }
    setTouchedFields((prev) => ({ ...prev, muscles: true }));
  };

  const handleSelectType = (e: any) => {
    setExercise({
      ...exercise,
      exerciseType: e.target.value,
    });
    setTouchedFields((prev) => ({ ...prev, exerciseType: true }));
  };

  const handleSelectEquipment = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setExercise({
      ...exercise,
      equipment: e.target.value,
    });
    setTouchedFields((prev) => ({ ...prev, equipment: true }));
  };

  const handleImageChange = (imageData: ImageFile) => {
    // Use the full Data URI instead of extracting just the base64 part
    setExercise({
      ...exercise,
      image: imageData.dataUri,
    });
    // Only mark image as touched
    setTouchedFields((prev) => ({ ...prev, image: true }));
  };

  // Improved function to get only the touched fields
  const getTouchedFields = (): Partial<ExerciseForm> => {
    if (!id) {
      // If creating a new exercise, send all fields
      return exercise;
    }

    // For updates, only include touched fields
    const touchedData: Partial<ExerciseForm> = {};

    // No need to include ID in the body - it will be passed separately in the URL
    // The ID should NOT be part of the data object being sent

    // Add only touched fields to our update payload
    for (const key in touchedFields) {
      if (touchedFields[key] === true) {
        switch (key) {
          case "image":
            if (exercise.image) {
              touchedData.image = exercise.image;
            }
            break;
          case "muscles":
            if (Array.isArray(exercise.muscles)) {
              touchedData.muscles = [...exercise.muscles];
            }
            break;
          case "name":
            touchedData.name = exercise.name;
            break;
          case "description":
            touchedData.description = exercise.description;
            break;
          case "repCount":
            touchedData.repCount = exercise.repCount;
            break;
          case "setCount":
            touchedData.setCount = exercise.setCount;
            break;
          case "exerciseType":
            touchedData.exerciseType = exercise.exerciseType;
            break;
          case "equipment":
            touchedData.equipment = exercise.equipment;
            break;
        }
      }
    }

    return touchedData;
  };

  const validateForm = () => {
    if (!exercise.exerciseType) {
      toast.error("Please select an Exercise Type");
      return false;
    }
    return true;
  };

  const handleFormSubmit = async () => {
    if (validateForm()) {
      if (id) {
        // For updates, pass only the touched data
        const dataToSubmit = getTouchedFields();

        // Log what fields are being sent
        console.log("Updating with fields:", Object.keys(dataToSubmit));

        // The id will be passed separately through the URL in updateExercise function
        await handleSubmit(dataToSubmit as unknown as ExerciseForm);
      } else {
        // For new exercises, pass the complete form
        await handleSubmit(exercise);
      }
      handleClose();
    }
  };

  return (
    <Modal size="3xl" position="top-center" show={isOpen} onClose={handleClose}>
      <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
        <strong>{id ? "Edit" : "Add"} Exercise</strong>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <div className="flex min-h-52 items-center justify-center ">
            <Spinner theme={blueThemeSpinner} color="info" size="xl" />
          </div>
        ) : (
          <div>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <Label htmlFor="name" className="font-semibold">
                  Name
                </Label>
                <div className="mt-1">
                  <TextInput
                    id={`name-${props.exercise.id}`}
                    name="name"
                    value={exercise?.name}
                    placeholder="Name"
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div>
                <Label className="font-semibold">Exercise Type</Label>
                <div className="mt-1">
                  <select
                    onChange={handleSelectType}
                    name="exercise"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option selected disabled>
                      Choose
                    </option>
                    {typesData.data?.typesData.map((x: any) => {
                      return (
                        <option
                          key={x.id}
                          value={x.id}
                          selected={x.id === exercise.exerciseType}
                        >
                          {x.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div>
                <Label className="font-semibold">Equipment</Label>
                <div className="mt-1">
                  <select
                    onChange={handleSelectEquipment}
                    value={exercise.equipment}
                    name="equipment"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  >
                    <option value="" selected disabled>
                      Choose
                    </option>
                    {equipment.data?.map((item: Equipment) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div>
                <Label htmlFor="repCount" className="font-semibold">
                  Rep Count
                </Label>
                <div className="mt-1">
                  <TextInput
                    id={`repCount-${props.exercise.id}`}
                    name="repCount"
                    value={exercise?.repCount || ""}
                    placeholder="e.g. 10-12"
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="setCount" className="font-semibold">
                  Set Count
                </Label>
                <div className="mt-1">
                  <TextInput
                    id={`setCount-${props.exercise.id}`}
                    name="setCount"
                    value={exercise?.setCount || ""}
                    placeholder="e.g. 3-4"
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <Label htmlFor="image" className="font-semibold">
                  Exercise Image
                </Label>
                <FileUploadZone
                  acceptedTypes={[
                    "image/jpeg",
                    "image/png",
                    "image/webp",
                    "image/gif",
                  ]}
                  maxSize={5242880}
                  defaultPreview={
                    exercise.imageUrl
                      ? {
                          url: exercise.imageUrl,
                          type: "image/webp",
                          name: "Current Image",
                        }
                      : undefined
                  }
                  onImageChange={handleImageChange}
                  maxDimensions={{ width: 2000, height: 2000 }}
                />
                {exercise.imageUrl && (
                  <p className="mt-2 text-xs text-gray-500">
                    Current image: {exercise.imageUrl.split("/").pop()}
                  </p>
                )}
              </div>
              <div className="sm:col-span-2">
                <Label htmlFor="description" className="font-semibold">
                  Description
                </Label>
                <div className="mt-1">
                  <Textarea
                    id={`description-${props.exercise.id}`}
                    name="description"
                    value={exercise?.description || ""}
                    placeholder="Exercise description"
                    onChange={handleInput}
                    rows={4}
                  />
                </div>
              </div>
            </div>
            <div className="py-4">
              <Label className="font-semibold">Muscle Target</Label>
              {typesData.data?.musclesData &&
              typesData.data?.musclesData.length > 0 ? (
                <div className="mt-4 grid grid-cols-4 gap-6">
                  {typesData.data?.musclesData.map(
                    (muscle: Muscle, index: number) => (
                      <div key={index} className="flex items-center gap-2">
                        <Button
                          onClick={() =>
                            handleSelectMuscle({ target: { value: muscle.id } })
                          }
                          className={`w-full ${exercise?.muscles?.includes(muscle.id) ? "bg-primary-700" : "bg-primary-300"}`}
                        >
                          {muscle.name}
                        </Button>
                      </div>
                    ),
                  )}
                </div>
              ) : (
                <div className="flex min-h-52 w-full items-center justify-center ">
                  <Spinner theme={blueThemeSpinner} color="info" size="xl" />
                </div>
              )}
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {id ? (
          <Button
            color="primary"
            disabled={updating}
            onClick={handleFormSubmit}
          >
            {updating ? "Updating..." : "Update"}
          </Button>
        ) : (
          <Button
            color="primary"
            disabled={updating}
            onClick={handleFormSubmit}
          >
            {updating ? "Creating..." : "Create"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ExerciseModal;
