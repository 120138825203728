import { Button, Modal } from "flowbite-react";
import { useState } from "react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { toast } from "react-toastify";
import type { ExerciseForm } from "types/apiResponses";
import { deleteExercise } from "../../services/exercises";
import * as Sentry from "@sentry/react";

interface DeleteExerciseModalProps {
  exercise: ExerciseForm;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  getExercises: () => void;
}

const DeleteExerciseModal = function (props: DeleteExerciseModalProps) {
  const { exercise, isOpen, setIsOpen } = props;
  const [deleting, setDelete] = useState(false);

  const handleDeleteExercise = async () => {
    setDelete(true);
    const result = await deleteExercise(exercise.id);
    if (result.data.error) {
      console.error("Error deleting exercise", result.data.error);
      Sentry.captureMessage("Error deleting exercise", {
        extra: {
          id: exercise.id,
        },
      });
      toast.error(
        "Error deleting exercise, make sure there are no videos related to this exercise",
      );
    } else {
      toast.success(`Exercise with name ${props.exercise.name} deleted`);
      await props.getExercises();
    }
    setDelete(false);
    setIsOpen(false);
  };

  return (
    <Modal
      size="3xl"
      position="top-center"
      show={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <Modal.Header className="px-6 pb-0 pt-6">
        <span className="sr-only">Delete Exercise</span>
      </Modal.Header>
      <Modal.Body className="px-6 pb-6 pt-0">
        <div className="flex flex-col items-center gap-y-6 text-center">
          <HiOutlineExclamationCircle className="text-7xl text-red-500" />
          <p className="text-xl text-gray-500">
            Are you sure you want to delete this exercise?
          </p>
          <div className="flex items-center gap-x-3">
            <Button
              color="failure"
              disabled={deleting}
              onClick={handleDeleteExercise}
            >
              {deleting ? "Deleting..." : "Yes, I'm sure"}
            </Button>
            <Button color="gray" onClick={() => setIsOpen(false)}>
              No, cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteExerciseModal;
