import { Label } from "flowbite-react";
import * as Sentry from "@sentry/react";
import { FC, useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import * as videosAPI from "../../services/videos";
import axios, { isCancelReq } from "../../util/axios";
import config from "../../config/config";

const WelcomeVideo: FC = () => {
  const [welcomeVideo, setWelcomeVideo] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchCurrentWelcomeVideo = async () => {
      try {
        const response = await axios.get(`${config.defaults.api_url}/videos`, {
          params: {
            page: 1,
            take: 10,
            exercise_type: "Instructional",
            format: "short",
            is_welcome_video: true,
          },
        });
        if (response.data.results.videos?.[0]) {
          const video = response.data.results.videos[0];
          setWelcomeVideo({
            id: video.id,
            title: video.title,
          });
        }
      } catch (error) {
        if (!isCancelReq(error)) {
          console.error("Error fetching welcome video:", error);
        }
      }
    };

    fetchCurrentWelcomeVideo();
  }, []);

  const loadVideoOptions = async (query: string) => {
    try {
      const response = await videosAPI.getAllVideos({
        search: query || undefined,
        page: 1,
        take: 20,
        format: "short",
        exercise_type: "Instructional",
        is_welcome_video: false,
      });

      return response.data.results.videos.map((video: any) => ({
        value: video.id,
        label: video.title,
      }));
    } catch (error) {
      console.error("Error loading videos:", error);
      return [];
    }
  };

  const handleVideoChange = async (videoId: string) => {
    setIsLoading(true);
    try {
      await videosAPI.setWelcomeVideo(videoId);
      toast.success("Welcome Video updated successfully!");
    } catch (error) {
      toast.error("Failed to update welcome video");
      console.error("Error updating welcome video:", error);
      Sentry.captureMessage("Failed to update welcome video", {
        extra: {
          id: videoId,
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="max-w-md mb-6">
        <div className="mb-2 flex flex-row items-center gap-4">
          <Label className="font-semibold">Choose Welcome Video</Label>
          {isLoading && (
            <div className="animate-spin h-4 w-4 border-2 border-gray-500 rounded-full border-t-transparent" />
          )}
        </div>
        <AsyncSelect
          isClearable={true}
          defaultOptions
          cacheOptions
          loadOptions={loadVideoOptions}
          noOptionsMessage={() => "No results"}
          onChange={async (v: any) => {
            if (v) {
              setWelcomeVideo({
                id: v.value,
                title: v.label,
              });
              await handleVideoChange(v.value);
            }
          }}
        />
      </div>
      {welcomeVideo && (
        <div className="flex flex-wrap">
          <span className="flex cursor-pointer flex-wrap items-center justify-between rounded bg-gray-200 px-4 py-2 text-xs font-bold leading-loose hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 sm:text-sm">
            {welcomeVideo.title}
          </span>
        </div>
      )}
    </div>
  );
};

export default WelcomeVideo;
