import { Breadcrumb, Button, Label, TextInput, Textarea } from "flowbite-react";
import * as Sentry from "@sentry/react";
import type { FC } from "react";
import { sendNotification } from "../../services/notifications";
import { HiHome } from "react-icons/hi";
import { useState } from "react";
import NavbarSidebarLayout from "../../layouts/navbar-sidebar";
import { ToastContainer, toast } from "react-toastify";

const NotificationsPage: FC = function () {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notification, setNotification] = useState({
    title: "",
    body: "",
    imageUrl: "",
    data: {
      type: "",
      redirectTo: "",
    },
  } as any);

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setNotification((prev: any) => ({
      ...prev,
      [name]: value, // Dynamically update the corresponding field
    }));
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      const response = await sendNotification(notification);

      if (response.data.error) {
        toast.error(response.data.message);
        Sentry.captureMessage("Error sending notification", {
          extra: {
            notification,
          },
        });
      } else {
        toast.success("Notification has been created and sent successfully!");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      Sentry.captureMessage("Error sending notification", {
        extra: {
          notification,
        },
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <NavbarSidebarLayout isFooter={false}>
      <div className="block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
        <div className="mb-1 w-full">
          <div className="mb-4">
            <Breadcrumb className="mb-4">
              <Breadcrumb.Item href="/">
                <div className="flex items-center gap-x-3">
                  <HiHome className="text-xl" />
                  <span className="dark:text-white">Home</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item href="/notifications">
                Notifications
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="mb-4 block text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
            Notifications
          </div>
          <div className="flex flex-col items-center justify-center ">
            <div className="flex w-6/12 flex-col gap-4">
              <div>
                <Label htmlFor="firstName">Title</Label>
                <div className="mt-2">
                  <TextInput
                    id="title"
                    name="title"
                    placeholder="Title"
                    onChange={handleChange}
                    defaultValue={notification.title}
                  />
                </div>
              </div>
              <div>
                <Label>{"Message"}</Label>
                <div className="mt-2">
                  <Textarea
                    id="body"
                    name="body"
                    placeholder="Message"
                    onChange={handleChange}
                    defaultValue={notification.body}
                  />
                </div>
              </div>
              <Button color="primary" onClick={handleSubmit}>
                {isSubmitting ? "Sending..." : "Send"}
              </Button>
            </div>
          </div>
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
            theme="dark"
          />
        </div>
      </div>
    </NavbarSidebarLayout>
  );
};

export default NotificationsPage;
